import React from 'react'
import Layout from '../components/layout/layout'
import './404.scss'

const NotFoundPage = () => (
  <Layout className="page fourohfourpage">
    <h1>404</h1>
    <p>You just hit a route that doesn&#39;t exist. Gutted.</p>
  </Layout >
)

export default NotFoundPage
